import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faLocationArrow, faMapMarkedAlt, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import frog from './images/ridefrog.gif'; // Adjust the path to where your image is located
import penguin from './images/waitpenguin.gif';

const RideRequestForm = () => {
    const navigate = useNavigate();
    const [pickupLocation, setPickupLocation] = useState('Wahu');
    const [destination, setDestination] = useState('');
    const pickupInputRef = useRef(null);
    const destinationInputRef = useRef(null);
    const [isSubmitted, setIsSubmitted] = useState(false);


    useEffect(() => {
        const loadGooglePlacesAutocomplete = async () => {
            if (!window.google) {
                console.error("Google Maps JavaScript API not loaded");
                return;
            }

            // Autocomplete for pickup location
            const pickupAutocomplete = new window.google.maps.places.Autocomplete(pickupInputRef.current);
            pickupAutocomplete.addListener('place_changed', () => {
                const place = pickupAutocomplete.getPlace();
                setPickupLocation(place.formatted_address || '');
            });

            // Autocomplete for destination
            const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationInputRef.current);
            destinationAutocomplete.addListener('place_changed', () => {
                const place = destinationAutocomplete.getPlace();
                setDestination(place.formatted_address || '');
            });
        };

        loadGooglePlacesAutocomplete();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitted(true);
        const response = await fetch('https://us-central1-ohyeah-77efa.cloudfunctions.net/sendRideRequestToSlack', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({pickupLocation, destination}),
        });
        const responseData = await response.json();
        console.log(responseData);
    };

    const handleCurrentLocationClick = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async position => {
                const {latitude, longitude} = position.coords;

                // Use Google Geocoding API to get the address from the coordinates
                try {
                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCEOQjkhSDIik5FeVmnRiMhJIB1LKt8XEE`);
                    const data = await response.json();
                    if (data.results && data.results.length > 0) {
                        const address = data.results[0].formatted_address;
                        setPickupLocation(address);
                    } else {
                        console.error('Geocoding failed: No results found');
                    }
                } catch (error) {
                    console.error('Geocoding failed:', error);
                }
            }, error => {
                console.error('Error getting location:', error);
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };


    return (
        <div className="container mt-5">
            <div className="mb-3 text-start">
                <button className="btn btn-link text-secondary" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="back-button-icon"/>
                </button>
            </div>

            <div className="card p-4 bg-white"
                 style={{borderRadius: '20px', boxShadow: '0 8px 25px rgba(0, 0, 0, 0.1)'}}>
                <h2 className="cute-heading-r mb-4 text-center">Where to, Cutie?</h2>
                {isSubmitted ? (
                    // Display this after form is submitted
                    <div className="text-center">
                        <img src={penguin} alt="Request Sent" className="img-fluid"/>
                        <h3 className="mt-3">One sec, calling my butler.</h3>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                        <span className="input-group-text bg-white" style={{width: '2.5rem'}}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary" style={{width: '100%'}}/>
                        </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pickup Location"
                                ref={pickupInputRef}
                                value={pickupLocation}
                                onChange={(e) => setPickupLocation(e.target.value)}
                                required
                            />
                            <button type="button" className="btn btn-outline-secondary"
                                    onClick={handleCurrentLocationClick}>
                                <FontAwesomeIcon icon={faLocationArrow}/>
                            </button>
                        </div>

                        <div className="input-group mb-3">
                        <span className="input-group-text bg-white" style={{width: '2.5rem'}}>
                            <FontAwesomeIcon icon={faMapMarkedAlt} className="text-success" style={{width: '100%'}}/>
                        </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Destination"
                                ref={destinationInputRef}
                                value={destination}
                                onChange={(e) => setDestination(e.target.value)}
                                required
                            />
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-custom-primary">VROOOOM 🚗💨</button>
                        </div>
                    </form>
                )}
            </div>

            <div className="text-center mt-4">
                <img src={frog} alt="Cute Description" className="img-fluid"/>
            </div>
        </div>
    );
};

export default RideRequestForm;
