// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBdDHG2q-HWVwy9lj9MH7_ogctXBwFduiE",
    authDomain: "ohyeah-77efa.firebaseapp.com",
    databaseURL: "https://ohyeah-77efa-default-rtdb.firebaseio.com",
    projectId: "ohyeah-77efa",
    storageBucket: "ohyeah-77efa.appspot.com",
    messagingSenderId: "639722631931",
    appId: "1:639722631931:web:699067aad1a580f87bf65e",
    measurementId: "G-QGBQNFZXXQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore and export it
export const db = getFirestore(app);
export default app;