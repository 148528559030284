import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './MoodSlider.css';
import birdHappy from './images/happybird.svg'; // Ensure the path is correct
import birdSad from './images/sadbird.svg'; // Ensure the path is correct
import birdPeaceful from './images/peacefulbird.svg'; // Add your custom SVG/image
import birdAngry from './images/angrybird.svg';
import birdSatiated from './images/satiatedbird.svg';
import birdHungry from './images/hungrybird.svg';
import birdRelaxed from './images/relaxedbird.svg';
import birdStressed from './images/stressedbird.svg';
import birdEnergetic from './images/energeticbird.svg';
import birdTired from './images/tiredbird.svg';
import birdSatisfied from './images/satisfiedbird.svg';
import birdInTheMood from './images/inthemoodbird.svg';
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import {db} from "./firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const MoodIndicatorPage = () => {
    const [moodValue, setMoodValue] = useState(50); // Slider starts in the middle
    const [peacefulAngryValue, setPeacefulAngryValue] = useState(50);
    const [satiatedHungryValue, setSatiatedHungryValue] = useState(50);
    const [relaxedStressedValue, setRelaxedStressedValue] = useState(50);
    const [tiredEnergeticValue, setTiredEnergeticValue] = useState(50);
    const [satisfiedInTheMoodValue, setSatisfiedInTheMoodValue] = useState(50);
    const [submitStatus, setSubmitStatus] = useState('🌈✨MUCH WOW✨🌈'); // New state for button text

    const navigate = useNavigate();

    const saveMoodData = async (moodData) => {
        const moodRef = doc(db, 'moods', 'latest');
        await setDoc(moodRef, moodData);
    };

    useEffect(() => {
        const fetchMoodData = async () => {
            const moodRef = doc(db, 'moods', 'latest');
            const docSnap = await getDoc(moodRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                setMoodValue(data.moodValue); // Set state for each mood slider based on fetched data
                setPeacefulAngryValue(data.peacefulAngryValue);
                setSatiatedHungryValue(data.satiatedHungryValue);
                setRelaxedStressedValue(data.relaxedStressedValue);
                setTiredEnergeticValue(data.tiredEnergeticValue);
                setSatisfiedInTheMoodValue(data.satisfiedInTheMoodValue);
            } else {
                console.log("No such document!");
            }
        };

        fetchMoodData();
    }, []);

    const handleSubmit = async () => {
        setSubmitStatus('Mood. 🐄');
        const moodData = {
            moodValue, peacefulAngryValue, satiatedHungryValue, relaxedStressedValue, tiredEnergeticValue, satisfiedInTheMoodValue
        };

        /// Define the Firebase Function URL
        // Replace <your-project-id> with your actual Firebase project ID
        const functionUrl = 'https://us-central1-ohyeah-77efa.cloudfunctions.net/sendMoodUpdateToSlack';

        try {
            // Send the mood data to the Firebase Function
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(moodData),
            });

            const responseData = await response.json();

            // Handle the response from your Firebase Function
            console.log(responseData.message);

            // Optionally, clear the form or give feedback to the user
        } catch (error) {
            console.error('Error sending mood update:', error);
        }

        // Save to Firestore
        saveMoodData(moodData);
    };

    // Function to calculate thumb color based on slider value
    const calculateHSThumbColor = (value) => {
        const startColor = { r: 58, g: 204, b: 227 }; // Corresponds to --slider-gradient-start
        const endColor = { r: 253, g: 255, b: 182 }; // Corresponds to --slider-gradient-end
        const mix = value / 100;
        // Linearly interpolate between start and end colors
        const r = Math.round(startColor.r * (1 - mix) + endColor.r * mix);
        const g = Math.round(startColor.g * (1 - mix) + endColor.g * mix);
        const b = Math.round(startColor.b * (1 - mix) + endColor.b * mix);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const calculatePAThumbColor = (value) => {
        const startColor = { r: 168, g: 222, b: 181 }; // Corresponds to --slider-gradient-start
        const endColor = { r: 251, g: 167, b: 167 }; // Corresponds to --slider-gradient-end
        const mix = value / 100;
        // Linearly interpolate between start and end colors
        const r = Math.round(startColor.r * (1 - mix) + endColor.r * mix);
        const g = Math.round(startColor.g * (1 - mix) + endColor.g * mix);
        const b = Math.round(startColor.b * (1 - mix) + endColor.b * mix);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const calculateSHThumbColor = (value) => {
        const startColor = { r: 253, g: 226, b: 228 }; // Corresponds to --slider-gradient-start
        const endColor = { r: 84, g: 45, b: 74 }; // Corresponds to --slider-gradient-end
        const mix = value / 100;
        // Linearly interpolate between start and end colors
        const r = Math.round(startColor.r * (1 - mix) + endColor.r * mix);
        const g = Math.round(startColor.g * (1 - mix) + endColor.g * mix);
        const b = Math.round(startColor.b * (1 - mix) + endColor.b * mix);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const calculateRSThumbColor = (value) => {
        const startColor = { r: 173, g: 216, b: 230 }; // Corresponds to --slider-gradient-start
        const endColor = { r: 255, g: 204, b: 203 }; // Corresponds to --slider-gradient-end
        const mix = value / 100;
        // Linearly interpolate between start and end colors
        const r = Math.round(startColor.r * (1 - mix) + endColor.r * mix);
        const g = Math.round(startColor.g * (1 - mix) + endColor.g * mix);
        const b = Math.round(startColor.b * (1 - mix) + endColor.b * mix);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const calculateTEThumbColor = (value) => {
        const startColor = { r: 255, g: 0, b: 195 }; // Corresponds to --slider-gradient-start
        const endColor = { r: 126, g: 80, b: 242 }; // Corresponds to --slider-gradient-end
        const mix = value / 100;
        // Linearly interpolate between start and end colors
        const r = Math.round(startColor.r * (1 - mix) + endColor.r * mix);
        const g = Math.round(startColor.g * (1 - mix) + endColor.g * mix);
        const b = Math.round(startColor.b * (1 - mix) + endColor.b * mix);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const calculateSIThumbColor = (value) => {
        const startColor = { r: 255, g: 196, b: 0 }; // Corresponds to --slider-gradient-start
        const endColor = { r: 255, g: 79, b: 79 }; // Corresponds to --slider-gradient-end
        const mix = value / 100;
        // Linearly interpolate between start and end colors
        const r = Math.round(startColor.r * (1 - mix) + endColor.r * mix);
        const g = Math.round(startColor.g * (1 - mix) + endColor.g * mix);
        const b = Math.round(startColor.b * (1 - mix) + endColor.b * mix);
        return `rgb(${r}, ${g}, ${b})`;
    };

    return (
        <div className="container mood-indicator-page" style={{ fontFamily: 'Poppins, sans-serif' }}>
            <h2 className="cute-heading mb-4 text-center">HRUML</h2>
            <div className="mb-3 text-start">
                <button className="btn btn-link text-secondary" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="back-button-icon"/>
                </button>
            </div>
            <div className="mood-slider-container">
                <div className="mood-slider-label">
                    <img src={birdHappy} style={{width: '50px'}} alt="Happy" />
                    <p className="lead mb-4 text-center">Happy ⇔ Sad</p>
                    <img src={birdSad} style={{width: '50px'}} alt="Sad" />
                </div>
                <input
                    type="range"
                    className="mood-slider happy-sad-slider"
                    value={moodValue}
                    onChange={(e) => setMoodValue(e.target.value)}
                    style={{ '--slider-thumb-color': calculateHSThumbColor(moodValue) }} // Applying the dynamic color
                />

                {/* Peaceful to Angry Slider */}
                <div className="mood-slider-label">
                    <img src={birdPeaceful} style={{width: '50px'}} alt="Peaceful" />
                    <p className="lead mb-4 text-center">Vibinnn ⇔ LEMME PUNCH U</p>
                    <img src={birdAngry} style={{width: '50px'}} alt="Angry" />
                </div>
                <input
                    type="range"
                    className="mood-slider peaceful-angry-slider" // Add unique class for styling
                    value={peacefulAngryValue}
                    onChange={(e) => setPeacefulAngryValue(e.target.value)}
                    style={{ '--slider-thumb-color': calculatePAThumbColor(peacefulAngryValue) }} // Dynamic color
                />

                <div className="mood-slider-label">
                    <img src={birdSatiated} style={{width: '50px'}} alt="Satiated" />
                    <p className="lead mb-4 text-center">Food Coma ⇔ AYCE. Now.</p>
                    <img src={birdHungry} style={{width: '50px'}} alt="Hungry" />
                </div>
                <input
                    type="range"
                    className="mood-slider satiated-hungry-slider" // Add unique class for styling
                    value={satiatedHungryValue}
                    onChange={(e) => setSatiatedHungryValue(e.target.value)}
                    style={{ '--slider-thumb-color': calculateSHThumbColor(satiatedHungryValue) }} // Dynamic color
                />

                <div className="mood-slider-label">
                    <img src={birdRelaxed} style={{width: '50px'}} alt="Relaxed" />
                    <p className="lead mb-4 text-center">Chill ⇔ Stressed</p>
                    <img src={birdStressed} style={{width: '50px'}} alt="Stressed" />
                </div>
                <input
                    type="range"
                    className="mood-slider relaxed-stressed-slider" // Add unique class for styling
                    value={relaxedStressedValue}
                    onChange={(e) => setRelaxedStressedValue(e.target.value)}
                    style={{ '--slider-thumb-color': calculateRSThumbColor(relaxedStressedValue) }} // Dynamic color
                />

                <div className="mood-slider-label">
                    <img src={birdEnergetic} style={{width: '50px'}} alt="Energetic" />
                    <p className="lead mb-4 text-center">Girl Boss Mode ⇔ eeeeepy</p>
                    <img src={birdTired} style={{width: '50px'}} alt="Tired" />
                </div>
                <input
                    type="range"
                    className="mood-slider tired-energetic-slider" // Add unique class for styling
                    value={tiredEnergeticValue}
                    onChange={(e) => setTiredEnergeticValue(e.target.value)}
                    style={{ '--slider-thumb-color': calculateTEThumbColor(tiredEnergeticValue) }} // Dynamic color
                />

                <div className="mood-slider-label">
                    <img src={birdSatisfied} style={{width: '50px'}} alt="Relaxed" />
                    <p className="lead mb-4 text-center">Satisfied ⇔ Dire need of "reward"</p>
                    <img src={birdInTheMood} style={{width: '50px'}} alt="Stressed" />
                </div>
                <input
                    type="range"
                    className="mood-slider satisfied-horny-slider" // Add unique class for styling
                    value={satisfiedInTheMoodValue}
                    onChange={(e) => setSatisfiedInTheMoodValue(e.target.value)}
                    style={{ '--slider-thumb-color': calculateSIThumbColor(satisfiedInTheMoodValue) }} // Dynamic color
                />

                <button onClick={handleSubmit} className="submit-mood">{submitStatus}</button>
            </div>
        </div>
    );
};

export default MoodIndicatorPage;
