import React, { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { developerEmail } from './DeveloperConfig'; // Make sure this exports your developer email correctly
import { useNavigate } from 'react-router-dom';
import EXIF from 'exif-js';

const DeveloperUploadPage = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;

    useEffect(() => {
        if (!user || user.email !== developerEmail) {
            navigate('/');
        }
    }, [navigate, user]);

    const handleFileUpload = async (event) => {
        const files = event.target.files;
        if (!files.length) {
            return;
        }

        Array.from(files).forEach(file => {
            EXIF.getData(file, function () {
                const lat = EXIF.getTag(this, 'GPSLatitude');
                const lon = EXIF.getTag(this, 'GPSLongitude');
                const latitude = lat ? convertDMSToDecimal(lat[0], lat[1], lat[2], EXIF.getTag(this, 'GPSLatitudeRef')) : null;
                const longitude = lon ? convertDMSToDecimal(lon[0], lon[1], lon[2], EXIF.getTag(this, 'GPSLongitudeRef')) : null;

                const metadata = {
                    customMetadata: {
                        location: latitude && longitude ? `${latitude},${longitude}` : 'Unknown location',
                    },
                };

                uploadFile(file, metadata);
            });
        });
    };

    const uploadFile = async (file, metadata) => {
        const storage = getStorage();
        const fileRef = storageRef(storage, `photos/${Date.now()}_${file.name}`);

        try {
            const uploadTaskSnapshot = await uploadBytes(fileRef, file, metadata);
            const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
            console.log('File available at', downloadURL);
            // You might want to keep track of successful uploads here
        } catch (error) {
            console.error('Error uploading photo:', error);
            // Handle upload errors here
        }
    };

    const convertDMSToDecimal = (degrees, minutes, seconds, direction) => {
        let decimal = degrees + minutes / 60 + seconds / (60 * 60);
        decimal = direction === 'S' || direction === 'W' ? decimal * -1 : decimal;
        return decimal;
    };

    if (!user || user.email !== developerEmail) {
        return null; // or loading indicator or redirect
    }

    return (
        <div>
            <h1>Developer Photo Upload</h1>
            <input type="file" multiple onChange={handleFileUpload} />
        </div>
    );
};

export default DeveloperUploadPage;
