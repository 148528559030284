import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import logo from './images/AFLogo.gif'; // Adjust the path to where your image is located
import brandLogo from './images/brandLogo.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css';
import {doc, getDoc} from "firebase/firestore";
import {db} from "./firebase";


const HomePage = () => {

    const [randomEndearment, setRandomEndearment] = useState('');
    const [daysUntilMeeting, setDaysUntilMeeting] = useState(null);
    const welcomeMarginClass = daysUntilMeeting !== null && daysUntilMeeting >= 0 ? "mb-1" : "mb-4";

    // Define your text options
    const cuteEndearments = [
        "Elli",
        "Love",
        "Baby",
        "The LOML",
        "My Cutie Patootie",
        "Babyyyy",
        "Cutie Precious",
        "Princess Peach",
        "Vintage Coochie"
    ];

    useEffect(() => {
        const loadNextMeetingDate = async () => {
            const docRef = doc(db, 'settings', 'nextTime');
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const nextMeetingDate = docSnap.data().date.toDate();
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                nextMeetingDate.setHours(0, 0, 0, 0);

                const difference = nextMeetingDate - today;
                const daysUntil = Math.ceil(difference / (1000 * 60 * 60 * 24));

                setDaysUntilMeeting(daysUntil);
            }
        };
        loadNextMeetingDate();
    }, []);


    useEffect(() => {
        // Function to select a random saying
        const getRandomEndearment = () => {
            const randomIndex = Math.floor(Math.random() * cuteEndearments.length);
            return cuteEndearments[randomIndex];
        };

        // Set the random saying on component mount
        setRandomEndearment(getRandomEndearment());
    }, []); // Empty dependency array ensures this effect runs only once on mount

    // Function to get the appropriate greeting based on current CST time
    const getCSTGreeting = () => {
        // Create a Date object for the current time
        let now = new Date();

        // Convert local time to CST (UTC-6) or CDT (UTC-5 if daylight saving time)
        let offset = now.getTimezoneOffset() * 60000; // Convert offset to milliseconds
        let utc = now.getTime() + offset; // UTC time
        let cstOffset = 6 * 60 * 60000; // CST offset in milliseconds (UTC-6)
        // Adjust the cstOffset if daylight saving time is in effect
        // For simplicity, let's assume daylight saving time is from March to November
        let month = now.getUTCMonth(); // 0-indexed, so 2 = March and 10 = November
        if (month >= 2 && month <= 10) { // DST months
            cstOffset = 5 * 60 * 60000; // CDT offset (UTC-5)
        }
        let cstTime = new Date(utc - cstOffset);

        // Get hours in CST
        let hour = cstTime.getHours();

        // Determine the appropriate greeting
        if (hour < 12) {
            return 'Good Morning';
        } else if (hour < 18) {
            return 'Good Afternoon';
        } else if (hour < 22) {
            return 'Good Evening';
        } else {
            return 'Good Night';
        }
    };

    // Store the greeting in a variable
    const greeting = getCSTGreeting();

    return (
        <div className="main-content">
        <div className="container py-5">
            {/* Navigation */}
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={brandLogo} alt="Brand Logo" style={{ width: 'auto', height: '150px' }} />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link cute-nav-link" to="/mood">HRUML</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link cute-nav-link" to="/goals">Dream</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link cute-nav-link" to="/ride">Scoop</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link cute-nav-link" to="/eat">Hungy</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link cute-nav-link" to="/guessr">ElliGuessr</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="text-center" style={{marginTop: '5%'}}>
                <h1 className="cute-heading mb-3">{greeting}, {randomEndearment}.</h1>
                <p className={`lead ${welcomeMarginClass}`}>Welcome to The Henry, an Elli-exclusive club.</p>
                {daysUntilMeeting !== null && daysUntilMeeting >= 0 && (
                    <p className="mb-4"><strong className="fs-6">{daysUntilMeeting} {daysUntilMeeting === 1 ? 'day' : 'days'}</strong> until I see my baby{daysUntilMeeting > 3 ? '.' : '!'}</p>
                )}
                <Link to="/motd" className="btn btn-custom-primary me-2">MOTD</Link>
                <Link to="/guessr" className="btn btn-custom-outline-primary">Bored?</Link>
            </div>

            {/* Cute Logo Illustration */}
            <div className="text-center mt-5">
                <img src={logo} style={{width: '40%'}} alt="Cute Logo" className="img-fluid" />
            </div>
        </div>
            {/* Footer */}
            <footer className="cute-footer">
                <div>©2024 The Henry. All rights reserved for Elli.</div>
            </footer>
        </div>
    );
};

export default HomePage;
