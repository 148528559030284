import React, {useState, useEffect} from 'react';
import './ValentinePage.css'; // Make sure to create and link this CSS file
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faHeart} from '@fortawesome/free-solid-svg-icons';
import {faTimes} from '@fortawesome/free-solid-svg-icons'; // If you want a specific "no" icon
import frogOG from './images/frog9.svg';
import frog1 from './images/frog8.svg';
import frog2 from './images/frog7.svg';
import frog3 from './images/frog6.svg';
import frog4 from './images/frog5.svg';
import frog5 from './images/frog4.svg';
import frog6 from './images/frog3.svg';
import frog7 from './images/frog2.svg';
import frog8 from './images/frog1.svg';
import frogLove from './images/frogLove.gif';
import {useNavigate} from "react-router-dom";

const frogImages = [
    frogOG, frog1, frog2, frog3, frog4, frog5, frog6, frog7, frog8
];


const sadTexts = [
    "",
    "I think you missclicked haha",
    "You're so silly, you missclicked again",
    "Oh. It wasn't a missclick?",
    "How could you???",
    "Arson's getting sad",
    "You monster! You're making ARSON CRY",
    "I sad",
    "I'll never give up...",
];

const ValentinePage = () => {
    const navigate = useNavigate();
    const [frogState, setFrogState] = useState(0);
    const [textState, setTextState] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const checkDevice = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        checkDevice();
        window.addEventListener('resize', checkDevice);

        return () => window.removeEventListener('resize', checkDevice);
    }, []);

    const handleNoClick = (e) => {
        if (!isMobile) {
            // Calculate new position
            const newX = (Math.round(Math.random()) * 2 - 1) * Math.random() * (.5 * window.innerWidth - e.target.offsetWidth);
            const newY = Math.random() * (-.85 * window.innerHeight - e.target.offsetHeight);

            console.log(newX);

            // Apply new position with transform for animation
            e.target.style.transform = `translate(${newX}px, ${newY}px)`;
        } else {
            // Mobile behavior remains the same
            setFrogState((prevState) => (prevState + 1) % frogImages.length);
            setTextState((prevState) => (prevState + 1) % sadTexts.length);
        }
    };

    const handleSubmit = async () => {
        // Preventing default form submission behavior if you're using a <form> tag
        // event.preventDefault();
        setIsSubmitted(true);
        const valentineResponse = "Of Course :)"; // Since this is the positive response scenario
        const response = await fetch('https://us-central1-ohyeah-77efa.cloudfunctions.net/sendValentineRequestToSlack', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({response: valentineResponse}),
        });
        const responseData = await response.json();
        console.log(responseData);
    };

    return (
        <div className="valentine-page">
            <div className="back-button-container mb-3 text-start">
                <button className="btn btn-link text-secondary" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="back-button-icon"/>
                </button>
            </div>
            {isMobile && (
                <>
                    <br/>
                    <br/>
                </>
            )}
            <h2>Will Elliana Renee Nordhaus be my Valentine?</h2>
            {isMobile && !isSubmitted && (
                <>
                    <img src={frogImages[frogState]} alt="Frog's mood" className="frog-image"/>
                    <p className="sad-text">{sadTexts[textState]}</p>
                </>
            )}
            {!isMobile && !isSubmitted && (
                <img src={frogOG} alt="Frog's mood" className="frog-image"/>
            )}
            {!isMobile && isSubmitted && (
                <>
                <img src={frogLove} alt="Frog's mood" className="frog-image"/>
                    <h2> YAYAYAYAYA!</h2>
                    <p><FontAwesomeIcon icon={faHeart}/> <FontAwesomeIcon icon={faHeart}/> <FontAwesomeIcon icon={faHeart}/> </p>
                </>
            )}
            {isMobile && isSubmitted && (
                <>
                    <img src={frogLove} alt="Frog's mood" className="frog-image"/>
                    <h2> YAYAYAYAYA!</h2>
                    <p><FontAwesomeIcon icon={faHeart}/> <FontAwesomeIcon icon={faHeart}/> <FontAwesomeIcon icon={faHeart}/> </p>
                </>
            )}
            {!isSubmitted && (
                <>
                    <div className="response-buttons">
                        <button onClick={handleSubmit} className="yes-button">
                            <FontAwesomeIcon icon={faHeart} className="icon-space"/> Of Course :)
                        </button>
                        <button className="no-button" onClick={handleNoClick}
                                onMouseOver={!isMobile ? handleNoClick : null}>
                            <FontAwesomeIcon icon={faTimes} className="icon-space"/> No Thanks.
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default ValentinePage;
