import React, { useEffect, useState } from 'react';
import DraggableItem from "./DraggableItem";
import { db } from './firebase';
import { collection, onSnapshot, addDoc, doc, updateDoc, getDoc, deleteDoc} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { FaPlus, FaImage, FaRegEdit, FaTrash } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap'; // Assuming you're using react-bootstrap for modals
import { useNavigate } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const BulletinBoard = () => {
    const navigate = useNavigate();
    const { itemId } = useParams();
    const [items, setItems] = useState([]);
    const [title, setTitle] = useState('');
    const [editingTitle, setEditingTitle] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItemForDeletion, setSelectedItemForDeletion] = useState(null);

// Method to select an item for deletion
    const selectItemForDeletion = (itemId) => {
        setSelectedItemForDeletion(itemId);
    };

// Method to delete the selected item
    const deleteSelectedItem = async () => {
        if (!selectedItemForDeletion) return;

        // Delete the item document from Firestore
        await deleteDoc(doc(db, `bulletinBoards/${itemId}/items`, selectedItemForDeletion));

        // Deselect item after deletion
        setSelectedItemForDeletion(null);
    };

    useEffect(() => {
        const fetchTitle = async () => {
            const categories = ['Dreaming', 'Planning', 'Progress', 'Achieved'];

            for (const category of categories) {
                const docRef = doc(db, "kanban", category);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const foundItem = docSnap.data().items.find(item => String(item.id) === String(itemId));


                    if (foundItem) {
                        setTitle(foundItem.text);
                        console.log("Title set to:", foundItem.text);
                        break;
                    } else {
                        console.log(`Item with id ${itemId} not found in category ${category}`);
                    }
                } else {
                    console.log(`No data found for category: ${category}`);
                }
            }
        };

        fetchTitle();
        const unsubscribeItems = onSnapshot(collection(db, `bulletinBoards/${itemId}/items`), (snapshot) => {
            const itemsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setItems(itemsData);
        });

        return () => {
            unsubscribeItems();
        };
    }, [itemId, db]);

    const deleteItemAndBoard = async () => {
        // Delete the bulletin board from Firestore
        await deleteDoc(doc(db, `bulletinBoards`, itemId));

        // Find and delete the item from the kanban collection
        const categories = ['Dreaming', 'Planning', 'Progress', 'Achieved'];
        categories.forEach(async (category) => {
            const catDocRef = doc(db, "kanban", category);
            const catDocSnap = await getDoc(catDocRef);
            if (catDocSnap.exists()) {
                const updatedItems = catDocSnap.data().items.filter(item => String(item.id) !== String(itemId));
                await updateDoc(catDocRef, { items: updatedItems });
            }
        });
        navigate('/goals');
    };

    const updateTitle = async (newTitle) => {
        setTitle(newTitle);
        setEditingTitle(false);

        const categories = ['Dreaming', 'Planning', 'Progress', 'Achieved'];
        let updateSuccess = false;

        for (const category of categories) {
            const catDocRef = doc(db, "kanban", category);
            const catDoc = await getDoc(catDocRef);

            if (catDoc.exists()) {
                const items = catDoc.data().items || [];
                const itemIndex = items.findIndex(item => String(item.id) === String(itemId));

                if (itemIndex !== -1) {
                    // Found the item, update its text
                    items[itemIndex].text = newTitle;
                    await updateDoc(catDocRef, { items: items });
                    updateSuccess = true;
                    break; // Exit the loop as we've found and updated the item
                }
            }
        }

        // If we successfully updated the title in kanban collection, no need to proceed further
        if (updateSuccess) return;

        // Fallback or additional logic if the item wasn't found in the categories
        console.error("Failed to update the title in kanban collection");
    };

    const uploadImageToStorage = async (file) => {
        const storage = getStorage();
        const storageRef = ref(storage, `bulletinImages/${itemId}/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        return getDownloadURL(snapshot.ref);
    };

    const addNewItemToFirestore = async (newItem) => {
        // Add new item to the specific bulletin board's subcollection
        await addDoc(collection(db, `bulletinBoards/${itemId}/items`), newItem);
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const imageUrl = await uploadImageToStorage(file);
        const newItem = {
            type: 'image',
            src: imageUrl,
            x: 100, // Initial position; consider allowing customization
            y: 100,
            // Add additional item properties as needed
        };
        await addNewItemToFirestore(newItem);
    };

    const handleAddTextbox = async () => {
        const newItem = {
            type: 'text',
            content: 'New Text',
            x: 100,
            y: 100,
            // Add additional item properties as needed
        };
        await addNewItemToFirestore(newItem);
    };

    const deleteItem = async (itemIdToDelete) => {
        // Delete the item from Firestore
        await deleteDoc(doc(db, `bulletinBoards/${itemId}/items`, itemIdToDelete));

        // Update local state to remove the item from the UI
        setItems(prevItems => prevItems.filter(item => item.id !== itemIdToDelete));
    };

    const handleSelectItem = (itemId) => {
        setSelectedItemForDeletion(itemId === selectedItemForDeletion ? null : itemId); // Toggle selection
    };

    function goBack() {
        navigate('/goals');
    }

    return (
        <div>
            {editingTitle ? (
                <input
                    value={title}
                    style={{ position: 'fixed', top: '10px', left: '10px', fontSize: '18px', fontWeight: 'bold' }}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={(e) => updateTitle(e.target.value)}
                    autoFocus
                />
            ) : (
                <h1
                    style={{ position: 'fixed', top: '10px', left: '10px' }}
                    onDoubleClick={() => setEditingTitle(true)}
                >
                    {title || 'Untitled'}
                </h1>
            )}

            <div style={{ position: 'fixed', top: '50px', right: '10px', display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon onClick={goBack} icon={faArrowLeft} style={{ cursor: 'pointer', marginRight: '10px' }} size={24}/>
                <label htmlFor="image-upload" style={{ cursor: 'pointer', marginRight: '10px' }}>
                    <FaImage size={24} />
                </label>
                <input id="image-upload" type="file" onChange={handleImageUpload} style={{ display: 'none' }} />

                <FaRegEdit onClick={handleAddTextbox} style={{ cursor: 'pointer', marginRight: '10px' }} size={24} />

                <FaTrash onClick={() => setShowDeleteModal(true)} style={{ cursor: 'pointer' }} size={24} />
            </div>

            {items.map((item, index) => (
                <DraggableItem
                    key={item.id}
                    item={item}
                    itemId={itemId}
                    onDelete={deleteItem}
                    onSelect={handleSelectItem}
                    isSelected={selectedItemForDeletion === item.id}
                />
            ))}

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Bulletin Board</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this bulletin board and its associated item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => { deleteItemAndBoard(); setShowDeleteModal(false); }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BulletinBoard;
