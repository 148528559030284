import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import logo from './images/brandLogo.png';

const AuthPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/home');
            }
        });
    }, [navigate]);

    const handleSignIn = (e) => {
        e.preventDefault();
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Sign-in success, redirect handled by onAuthStateChanged
            })
            .catch((error) => {
                console.error(error);
                alert("Failed to sign in.");
            });
    };

    return (
        <div className="d-flex flex-column align-items-center mt-5">
            <div className="mb-4">
                <img src={logo} alt="Logo" style={{ maxWidth: '150px' }} />
            </div>
            <div className="container p-4 shadow bg-white" style={{ maxWidth: '400px', borderRadius: '10px' }}>
                <h2 className="text-center mb-4" style={{ color: '#ff69b4' }}>Welcome</h2>
                <form onSubmit={handleSignIn}>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text" id="email-addon">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                            <input
                                type="email"
                                className="form-control"
                                id="emailInput"
                                aria-describedby="email-addon"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text" id="password-addon">
                                <FontAwesomeIcon icon={faLock} />
                            </span>
                            <input
                                type="password"
                                className="form-control"
                                id="passwordInput"
                                aria-describedby="password-addon"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                            />
                        </div>
                    </div>
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-block mx-auto" style={{ backgroundColor: '#ff69b4', color: 'white', width: 'auto' }}>Enter The Henry</button>
                     </div>
                </form>
            </div>
        </div>
    );
};

export default AuthPage;
