import React, {useRef, useState} from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './FoodPage.css';
import hungrypenguin from './images/hungrypenguin.gif';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faRandom} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import hungryfrog from "./images/hungryfrog.png";

const foodPresets = [
    { food: "Jet's Pizza", drink: "Cherry Pepsi" },
    { food: "Pizza from Blaze", drink: "" },
    { food: "Kyoto Sushi + Beef Teriyaki + Spicy Mayo", drink: "" },
    { food: "Noodle's Chicken Parm", drink: "" },
    { food: "Happy Meal", drink: "Frozen Blue Raspberry Fanta" },
    { food: "McChicken", drink: "Spicy Sprite" },
    { food: "Spicy Pork Bulgogi", drink: "" },
    { food: "Bonchon Soy Garlic Wings", drink: "" },
    { food: "Panera's Try 2 (Cheese: Grilled & Mac)", drink: "" },
    { food: "Olive Garden 5 Cheese Ziti", drink: "Raspberry Lemonade" },
    { food: "Cream Cheese Puffs + Pork Lo Mein", drink: "Watermelon Juice" },
    { food: "Cream Cheese Puffs", drink: "" },
    { food: "Noodle's Caesar Salad + Garlic Bread", drink: "" },
    { food: "TBell Chicken Quesadilla + Cinnabon Delights", drink: "Baja Blast" },
    { food: "Leeann Chin Bourbon + Orange Chicken", drink: "" },
    { food: "Denny's Cinnamon Pancakes", drink: "" },
    { food: "OPH French Toast", drink: "" },
    { food: "DP Dough Calzone", drink: "" },
    { food: "Insomnia's Cookies", drink: "" },
    { food: "Starbuck's Cinnamon Coffee Cake", drink: "Chai Latte" },
    { food: "Nothing Bundt Cake", drink: "" },
];

const quizFlow = {
    start: {
        question: "How hungy?",
        answers: {
            "I will eat you": "eatYou",
            "Snacky Time": "snackyTime",
            "Me Hungy": "hungy"
        },
    },
    eatYou: {
        question: "Oh yeah? Which part of me? 😈",
        answers: {
            "My breadstick": { food: "Olive Garden 5 Cheese Ziti", drink: "Raspberry Lemonade" },
            "My sushi roll": { food: "Kyoto Sushi + Beef Teriyaki + Spicy Mayo", drink: "" },
            "My Dough": { food: "DP Dough Calzone", drink: "" },
        },
    },
    snackyTime: {
        question: "What colors u fw rn? ‍🌈",
        answers: {
            "Green": { food: "Noodle's Caesar Salad + Garlic Bread", drink: "" },
            "Brown": { food: "Starbuck's Cinnamon Coffee Cake", drink: "Chai Latte" },
            "Purple": { food: "Insomnia's Cookies", drink: "" },
            "Red":  { food: "Cream Cheese Puffs", drink: "" },
            "Pink": { food: "Nothing Bundt Cake", drink: "" },
            "Yellow": { food: "McChicken", drink: "Spicy Sprite" }
        }
    },
    hungy: {
        question: "Fast or Fancy?",
        answers: {
            "Fast as fuck boiiiii": "fast",
            "Im bougieeee tn (sorta)": "fancy"
        }
    },
    fast: {
        question: "What's the vibes tho?",
        answers: {
            "I want cuddles": [ {food: "Noodle's Chicken Parm", drink: "" },{food: "Jet's Pizza", drink: "Cherry Pepsi"}],
            "Standard": [{ food: "Pizza from Blaze", drink: "" },{ food: "Leeann Chin Bourbon + Orange Chicken", drink: "" }],
            "HEHEHAHA": [{ food: "Happy Meal", drink: "Frozen Blue Raspberry Fanta" },{ food: "TBell Chicken Quesadilla + Cinnabon Delights", drink: "Baja Blast" }]
        }
    },
    fancy: {
        question: "Whats ur buds feeling?",
        answers: {
            "Cheese, as usual": { food: "Panera's Try 2 (Cheese: Grilled & Mac)", drink: "" },
            "Sweet, like you are": [{ food: "Denny's Cinnamon Pancakes", drink: "" }, { food: "OPH French Toast", drink: "" }],
            "Spicy, mmmm ;)": [{ food: "Spicy Pork Bulgogi", drink: "" }],
            "Salty, like... nvm": [{ food: "Cream Cheese Puffs + Pork Lo Mein", drink: "Watermelon Juice" },{ food: "Bonchon Soy Garlic Wings", drink: "" }]
        }
    }
};

const FoodPage = () => {
    const [showContent, setShowContent] = useState('buttons'); // Controls what to show: buttons, quiz, or form
    const [food, setFood] = useState('');
    const [drink, setDrink] = useState('');
    const [currentFlow, setCurrentFlow] = useState('start'); // Tracks the current position in the quizFlow
    const formRef = useRef(null); // Reference to the form for scrolling into view
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleIKnow = () => {
        setFood('');
        setDrink('');
        setShowContent('form');
        scrollToForm();
    };

    const handleRandom = () => {
        const randomPreset = foodPresets[Math.floor(Math.random() * foodPresets.length)];
        setFood(randomPreset.food);
        setDrink(randomPreset.drink);
        setShowContent('form');
        scrollToForm();
    };

    const handleQuizStart = () => {
        setCurrentFlow('start'); // Reset quiz to start
        setShowContent('quiz');
    };

    const handleQuizAnswer = (nextFlowKey) => {
        const nextStep = quizFlow[currentFlow].answers[nextFlowKey];

        if (typeof nextStep === 'string') { // Navigate to another question
            setCurrentFlow(nextStep);
        } else if (Array.isArray(nextStep) || typeof nextStep === 'object') { // Final answer or random selection from array
            if (Array.isArray(nextStep)) {
                const randomSelection = nextStep[Math.floor(Math.random() * nextStep.length)];
                setFood(randomSelection.food);
                setDrink(randomSelection.drink || ''); // Ensure drink is set, even if empty
            } else {
                setFood(nextStep.food);
                setDrink(nextStep.drink || '');
            }
            setShowContent('form');
            scrollToForm();
        }
    };

    const handleFormRandom = () => {
        handleRandom(); // Reuse the existing random handler
        scrollToForm(); // Ensure the form is in view after autofill
    };

// Function to reset and start the quiz over
    const handleRedoQuiz = () => {
        setCurrentFlow('start'); // Reset the quiz to its initial state
        setShowContent('quiz'); // Show the quiz content
    };

    const scrollToForm = () => {
        formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Show some UI indication (loading spinner, etc.) if needed
        setIsSubmitted(true);
        try {
            const response = await fetch('https://us-central1-ohyeah-77efa.cloudfunctions.net/sendFoodRequestToSlack', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ food, drink }),
            });
            const responseData = await response.json();
            console.log(responseData.message);

            // Handle success (show success message, clear form, etc.)
        } catch (error) {
            console.error('Failed to submit food request:', error);
            // Handle error (show error message, etc.)
        }
    };

    const adjustTextAreaHeight = (e) => {
        e.target.style.height = 'inherit'; // Reset the height
        e.target.style.height = `${e.target.scrollHeight}px`; // Set new height based on scroll height
    };

    return (
        <div className="page-container">
            <div className="back-button-container mb-3 text-start">
                <button className="btn btn-link text-secondary" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="back-button-icon"/>
                </button>
            </div>
            <h2 className="cute-heading text-center">Cravin a lil smth?</h2>
            <img src={hungrypenguin} alt="Hungry Penguin" style={{width: '25%', marginBottom: '20px'}} className="food-request-image" />

            <SwitchTransition mode="out-in">
                <CSSTransition key={showContent} timeout={300} classNames="fade">
                    <div>
                        {showContent === 'buttons' && (
                            <div className="buttons-container">
                                <button className="btn btn-custom-primary" onClick={handleIKnow}>Yuhhh 😋</button>
                                <button className="btn btn-custom-primary" onClick={handleQuizStart}>Yes, but idkkk what 😖</button>
                                <button className="btn btn-custom-primary" onClick={handleRandom}>Anything pls 🙏</button>
                            </div>
                        )}

                        {showContent === 'quiz' && (
                            <div className="quiz-container">
                                <p className="quiz-question">{quizFlow[currentFlow].question}</p>
                                {Object.keys(quizFlow[currentFlow].answers).map((key) => (
                                    <button key={key} className="quiz-button" onClick={() => handleQuizAnswer(key)}>
                                        {key}
                                    </button>
                                ))}
                            </div>
                        )}

                        {showContent === 'form' && !isSubmitted && (
                            <div ref={formRef} className="food-form">
                                <div className="random-icon-container" onClick={handleFormRandom}>
                                    <FontAwesomeIcon icon={faRandom} className="random-icon" />
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <label htmlFor="foodInput">Food:</label>
                                    <textarea id="foodInput" value={food} onChange={(e) => setFood(e.target.value)} onInput={adjustTextAreaHeight} />
                                    <label htmlFor="drinkInput">Drink:</label>
                                    <textarea id="drinkInput" value={drink} onChange={(e) => setDrink(e.target.value)} onInput={adjustTextAreaHeight} />
                                    <div className="form-actions">
                                        <button type="submit" className="btn-custom-submit">OMNOMNOMNOM 😋</button>
                                    </div>
                                    {currentFlow !== 'start' && <p className="redo-quiz" onClick={handleRedoQuiz}>Nah? Run it back.</p>}
                                </form>
                            </div>
                        )}

                        {showContent === 'form' && isSubmitted && (
                            <div className="text-center">
                                <img src={hungryfrog} alt="Request Sent" className="img-fluid"/>
                                <h3 className="mt-3">Don't fall asleep now!</h3>
                            </div>
                        )}
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
};

export default FoodPage;
