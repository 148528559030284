import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment-timezone'; // Import moment and moment-timezone
import frogHappy from './images/frogstate2.png';
import frogContent from './images/frogstate3.png';
import frogEcstatic from './images/frogstate4.png';
import frogNeutral from './images/frogstate1.png';
import { useInterval } from './useInterval';
import './MOTD.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const MOTD = () => {
    const navigate = useNavigate();
    const [frogState, setFrogState] = useState(0);
    const [message, setMessage] = useState('');
    const [petCount, setPetCount] = useState(0);
    const frogImages = [frogNeutral, frogHappy, frogContent, frogEcstatic];
    const [countdown, setCountdown] = useState('');
    const petsNeeded = 100;

    useEffect(() => {
        const fetchFrogStateAndMessage = async () => {
            const today = moment().tz('America/Chicago').format('YYYY-MM-DD'); // Get today's date in CST
            const messageDoc = doc(db, 'messages', today);
            const messageSnap = await getDoc(messageDoc);

            if (messageSnap.exists()) {
                setMessage(messageSnap.data().message);
            } else {
                console.log('No message for today');
            }

            const stateDoc = doc(db, 'state', 'frog');
            const stateSnap = await getDoc(stateDoc);

            if (stateSnap.exists() && moment().tz('America/Chicago').isSame(stateSnap.data().date, 'day')) {
                setFrogState(stateSnap.data().contentment);
            } else {
                // Reset frog state if it's a new day
                setFrogState(0);
                updateDoc(stateDoc, { contentment: 0, date: moment().tz('America/Chicago').toDate() });
            }
        };

        fetchFrogStateAndMessage();
    }, []);

    useInterval(() => {
        calculateCountdown();
        const nowCST = moment().tz('America/Chicago');
        if (nowCST.hours() === 0 && nowCST.minutes() === 0 && frogState !== 0) {
            // Reset state at midnight CST
            setFrogState(0);
            // Optionally update the state in Firestore
            const stateDoc = doc(db, 'state', 'frog');
            updateDoc(stateDoc, { contentment: 0, date: nowCST.toDate() });
        }
    }, 1000);

    const calculateCountdown = () => {
        const nowCST = moment().tz('America/Chicago');
        const midnightCST = nowCST.clone().endOf('day').add(1, 'second');
        const diff = midnightCST.diff(nowCST);

        const duration = moment.duration(diff);
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        setCountdown(`${hours}h ${minutes}m ${seconds}s`);
    };

    const resetFrogState = async (updateFirebase = false) => {
        setFrogState(0);
        setPetCount(0);
        if (updateFirebase) {
            // Update frog state in Firestore
            await updateDoc(doc(db, 'state', 'frog'), { contentment: 0 });
        }
    };

    const petFrog = async () => {
        if (frogState === 3) return; // Do not change state if already in last state

        setPetCount(petCount + 1);

        if (petCount + 1 >= petsNeeded) {
            let newState = (frogState + 1) % 4;
            setFrogState(newState);
            if (newState === 3) {
                sendPetNotification();
            }
            setPetCount(0); // Reset pet count

            await updateDoc(doc(db, 'state', 'frog'), { contentment: newState });
        }
    };

    const sendPetNotification = async () => {
            try {
                const response = await fetch('https://us-central1-ohyeah-77efa.cloudfunctions.net/sendPetNotification', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ date: new Date().toLocaleDateString() }),
                });
                if (!response.ok) throw new Error('Failed to send notification');
                console.log('Notification sent successfully');
            } catch (error) {
                console.error('Error sending notification:', error);
            }
    };

    return (
        <div className="motd-container">
            <div className="back-button-container mb-3 text-start" >
                <button className="btn btn-link text-secondary" onClick={() => navigate('/')}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="back-button-icon"/>
                </button>
            </div>
            <div className="arc-header">Message of the Day</div>
            <div className="frog" onMouseMove={petFrog} onTouchMove={petFrog}>
                <img src={frogImages[frogState]} alt="Frog" />
            </div>
            {frogState === 0 && (
                <>
                    <div className="message">
                        <h1>PET ME!!!</h1>
                    </div>
                </>
            )}
            {frogState === 1 && (
                <>
                    <div className="message">
                        <h2>MMMMMH</h2>
                    </div>
                </>
            )}
            {frogState === 2 && (
                <>
                    <div className="message">
                        <h2>HEHEHEH</h2>
                    </div>
                </>
            )}
            {frogState === 3 && (
                <>
                    <div className="message">
                        <p>"{message}"</p>
                    </div>
                    <div className="countdown">
                        <p>Next MOTD in: {countdown}</p>
                    </div>
                    <button className="pet-again-button" onClick={() => resetFrogState(true)}>
                        Pet me again
                    </button>
                </>
            )}
        </div>
    );
};

export default MOTD;
