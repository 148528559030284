import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import './GoalsPage.css';
import KanbanColumn from './KanbanColumn';
import {getAuth} from "firebase/auth";
import {db} from "./firebase";
import {arrayUnion, doc, onSnapshot, getDoc, setDoc, updateDoc} from "firebase/firestore";
import { DragDropContext } from 'react-beautiful-dnd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const GoalsPage = () => {
    const initialCategories = {
        Dreaming: [],
        Planning: [],
        Progress: [],
        Achieved: []
    };
    const [dataLoaded, setDataLoaded] = useState(false);
    const [categoriesState, setCategoriesState] = useState(initialCategories);
    const [showModal, setShowModal] = useState(false);
    const [newItem, setNewItem] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Dreaming');
    const [selectedPriority, setSelectedPriority] = useState('Unknown');
    const navigate = useNavigate();


    useEffect(() => {
        const unsubscribe = setupRealtimeListeners();
        return () => unsubscribe(); // Cleanup on component unmount
    }, []);

    const setupRealtimeListeners = () => {
        const categories = ['Dreaming', 'Planning', 'Progress', 'Achieved'];
        let unsubscribes = [];

        categories.forEach((category) => {
            const docRef = doc(db, 'kanban', category);
            const unsubscribe = onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                    setCategoriesState(prevState => ({
                        ...prevState,
                        [category]: doc.data().items || []
                    }));
                } else {
                    console.error(`No document found for ${category}`);
                    setCategoriesState(prevState => ({
                        ...prevState,
                        [category]: []
                    }));
                }
                setDataLoaded(true); // Ensure data loaded state is updated
            });
            unsubscribes.push(unsubscribe);
        });

        // Return a function to unsubscribe from all listeners when the component unmounts
        return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    };


    const onDragEnd = async (result) => {
        const { source, destination } = result;

        // If dropped outside the list or in the same position
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }

        // Move the item in the local state first
        const sourceColumn = categoriesState[source.droppableId];
        const destColumn = categoriesState[destination.droppableId];
        const [removed] = sourceColumn.splice(source.index, 1);
        destColumn.splice(destination.index, 0, removed);

        // Define a helper function to update or create a document
        const updateOrCreateDoc = async (docId, items) => {
            const docRef = doc(db, 'kanban', docId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                await updateDoc(docRef, { items: items });
            } else {
                await setDoc(docRef, { items: items });
            }
        };

        // Update Firebase documents
        await updateOrCreateDoc(source.droppableId, sourceColumn);
        if (source.droppableId !== destination.droppableId) {
            await updateOrCreateDoc(destination.droppableId, destColumn);
        }

        // Update local state
        setCategoriesState(prevState => ({
            ...prevState,
            [source.droppableId]: sourceColumn,
            [destination.droppableId]: destColumn,
        }));
    };


    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        const categories = ['Dreaming', 'Planning', 'Progress', 'Achieved'];
        let newState = {};

        const fetchPromises = categories.map(async (category) => {
            const docRef = doc(db, 'kanban', category);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                newState[category] = docSnap.data().items || [];
            } else {
                console.error(`No document found for ${category}`);
                newState[category] = [];
            }
        });

        // Wait for all fetch operations to complete
        await Promise.all(fetchPromises);

        // Update state and indicate that data has loaded
        setCategoriesState(newState);
        setDataLoaded(true); // This ensures that we switch off the loading state
    };

    const addItem = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const userEmail = user ? user.email : 'Unknown';
        const authorName = userEmail === 'elli@thehenry.club' ? 'Elli' : 'Henry';
        const newItemObject = { text: newItem, id: Date.now(), priority: selectedPriority, author: authorName };

        // Use helper function to get Firestore document ID
        const categoryDocRef = doc(db, 'kanban', selectedCategory);
        try {
            await updateDoc(categoryDocRef, {
                items: arrayUnion(newItemObject)
            });
        } catch (error) {
            console.error("Error updating document:", error);
            if (error.message.includes("No document to update")) {
                await setDoc(categoryDocRef, { items: [newItemObject] });
            }
        }

        fetchItems(); // Fetch items again to update UI
        setNewItem('');
        setShowModal(false);
    };


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="back-button-container mb-3 text-start" >
                <button className="btn btn-link text-secondary" onClick={() => navigate('/')}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="back-button-icon"/>
                </button>
            </div>
            <div className="container1 mt-3 bucket-list-page" >
                <div className="header1-container" style={{marginTop: '100px'}}>
                    <h2 className="header1">Our Dreams</h2>
                    <button className="add-goal-btn" onClick={() => setShowModal(true)}>+</button>
                </div>


                    {dataLoaded ? (
                    <div className="kanban-board d-flex justify-content-between">
                        {Object.entries(categoriesState).map(([category, items], index) => {
                            return (
                                <KanbanColumn key={category} droppableId={category} title={category === 'Progress' ? "In Progress" : category} items={items} />
                            );
                        })}
                    </div>
                ) : (
                    <div>Loading...</div> // Placeholder for loading state
                )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a New Goal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Goal</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your new goal"
                                value={newItem}
                                onChange={(e) => setNewItem(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                <option value="Dreaming">Dreaming</option>
                                <option value="Planning">Planning</option>
                                <option value="Progress">In Progress</option>
                                <option value="Achieved">Achieved</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Priority</Form.Label>
                            <Form.Select value={selectedPriority} onChange={(e) => setSelectedPriority(e.target.value)}>
                                <option>Unknown</option>
                                <option>Low</option>
                                <option>Medium</option>
                                <option>High</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addItem}>
                        Add Goal
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
        </DragDropContext>

    );
};

export default GoalsPage;
