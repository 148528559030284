import './firebase';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthPage from './AuthPage'; // Adjust the path as necessary
import HomePage from './HomePage'; // Adjust the path as necessary
import RidePage from './RidePage';
import MoodPage from './MoodPage';
import FoodPage from './FoodPage';
import ValentinePage from './ValentinePage';
import ProtectedRoute from './ProtectedRoute';
import DeveloperUploadPage from "./DeveloperUploadPage"; // Make sure to import ProtectedRoute
import ElliGuessrPage from "./ElliGuessrPage";
import GoalsPage from "./GoalsPage";
import BulletinBoard from "./BulletinBoard";
import MOTD from "./MOTD";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<AuthPage />} />
                <Route path="/home" element={
                    <ProtectedRoute>
                        <HomePage />
                    </ProtectedRoute>
                } />
                <Route path="/ride" element={
                    <ProtectedRoute>
                        <RidePage />
                    </ProtectedRoute>
                } />
                <Route path="/mood" element={
                    <ProtectedRoute>
                        <MoodPage />
                    </ProtectedRoute>
                } />
                <Route path="/eat" element={
                    <ProtectedRoute>
                        <FoodPage />
                    </ProtectedRoute>
                } />
                <Route path="/vday" element={
                    <ProtectedRoute>
                        <ValentinePage />
                    </ProtectedRoute>
                } />
                <Route path="/guessr" element={
                    <ProtectedRoute>
                        <ElliGuessrPage />
                    </ProtectedRoute>
                } />
                <Route path="/goals" element={
                    <ProtectedRoute>
                        <GoalsPage />
                    </ProtectedRoute>
                } />
                <Route path="/MOTD" element={
                    <ProtectedRoute>
                        <MOTD />
                    </ProtectedRoute>
                } />
                <Route path="/developer-upload" element={
                    <ProtectedRoute>
                        <DeveloperUploadPage />
                    </ProtectedRoute>
                } />
                <Route path="/bulletin/:itemId" element={
                    <ProtectedRoute>
                        <BulletinBoard />
                    </ProtectedRoute>
                } />
            </Routes>
        </Router>
    );
}


export default App;
