import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const KanbanColumn = ({ droppableId, title, items }) => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    // Function to navigate to the bulletin board page
    const navigateToBulletinBoard = (itemId) => {
        navigate(`/bulletin/${itemId}`);
    };

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'Low': return 'green';
            case 'Medium': return 'yellow';
            case 'High': return 'red';
            default: return 'grey';
        }
    };

    return (
        <Droppable droppableId={droppableId}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`kanban-column ${snapshot.isDraggingOver ? 'draggingOver' : ''}`}
                >
                    <h4>{title}</h4>
                    <div className="list-group">
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`list-group-item ${snapshot.isDragging ? 'dragging' : ''}`}
                                        onClick={() => navigateToBulletinBoard(item.id)} // Make the item clickable
                                    >
                                        <div className="item-text">{item.text}</div>
                                        <div className="item-details">
                                            <span className="item-priority">
                                                <span
                                                    className="priority-dot me-2"
                                                    style={{ backgroundColor: getPriorityColor(item.priority) }}
                                                ></span>
                                                {item.priority}
                                            </span>
                                            <span className="item-author">{item.author}</span>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                </div>
            )}
        </Droppable>
    );
};

export default KanbanColumn;
