import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { db } from './firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { FaTimes } from 'react-icons/fa';

const DraggableItem = ({ item, itemId, onDelete, isSelected, onSelect }) => {
    const [content, setContent] = useState(item.content || "");
    const [editing, setEditing] = useState(false);
    const itemRef = useRef(null); // Ref for the draggable item container

    useEffect(() => {
        // Adjust textarea height based on content, if it's a text item and not editing
        if (item.type === 'text' && !editing && itemRef.current) {
            itemRef.current.style.width = `${Math.min(itemRef.current.scrollWidth, 300)}px`; // Example: limit width to 300px or smaller based on content
        }
    }, [content, editing, item.type]);

    const handleStop = async (e, data) => {
        await updateDoc(doc(db, `bulletinBoards/${itemId}/items`, item.id), {
            x: data.x,
            y: data.y
        });
    };

    const handleChange = (e) => {
        setContent(e.target.value);
    };

    const handleBlur = async () => {
        setEditing(false);
        await updateDoc(doc(db, `bulletinBoards/${itemId}/items`, item.id), { content });
    };

    const handleClick = () => {
        if (!editing) onSelect(item.id);
    };

    return (
        <Draggable defaultPosition={{ x: item.x, y: item.y }} onStop={handleStop}>
            <div ref={itemRef} onDoubleClick={() => setEditing(true)} onClick={handleClick}
                 style={{ cursor: 'move', position: 'relative', maxWidth: '300px' }}>
                {isSelected && (
                    <FaTimes onClick={() => onDelete(item.id)}
                             style={{ position: 'absolute', top: -10, right: 100, cursor: 'pointer', color: 'red', zIndex: 1000 }}/>
                )}
                {item.type === 'text' ? (
                    editing ? (
                        <textarea value={content} onChange={handleChange} onBlur={handleBlur} autoFocus
                                  style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', resize: 'none' }}/>
                    ) : (
                        <div style={{ minHeight: '20px', minWidth: '60px' }}>{content}</div>
                    )
                ) : (
                    // For images, ensure they start off at a smaller size, e.g., max 150px width or height
                    <img src={item.src} alt="Bulletin item" draggable="false" style={{ maxWidth: '250px', maxHeight: '250px' }} />
                )}
            </div>
        </Draggable>
    );
};

export default DraggableItem;
