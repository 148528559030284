import React, { useEffect, useRef, useState } from 'react';
import './ElliGuessr.css';

const GuessMap = ({ actualLocation, onNext, submitGuess }) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [guessMarker, setGuessMarker] = useState(null);
    const [answerMarker, setAnswerMarker] = useState(null);
    const [line, setLine] = useState(null);
    const [isGuessSubmitted, setIsGuessSubmitted] = useState(false);
    const usaCenter = {lat: 37.0902, lng: -95.7129}; // Define this outside to use in reset

    const cuteMapStyle = [
        {
            "featureType": "all",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "weight": "2.00"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#9c9c9c"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#7b7b7b"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#46bcec"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#c8d7d4"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#070707"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        }
    ];

    useEffect(() => {
        const google = window.google;
        const initMap = () => {
            const map = new google.maps.Map(mapRef.current, {
                center: { lat: 37.0902, lng: -95.7129 },
                zoom: 4,
                styles: cuteMapStyle, // Apply the custom map style
                disableDefaultUI: true, // Disable default map UI
                zoomControl: true, // Enable zoom control separately if needed
                streetViewControl: false, // Disable street view
                mapTypeControl: false, // Disable map type control
            });
            setMap(map);
        };

        initMap();
    }, []);

    useEffect(() => {
        if (!map || isGuessSubmitted) return;

        const clickListener = map.addListener('click', (e) => {
            if (guessMarker) {
                guessMarker.setMap(null);
            }

            const marker = new window.google.maps.Marker({
                position: e.latLng,
                map: map,
            });
            setGuessMarker(marker);
        });

        return () => window.google.maps.event.clearListeners(map, 'click');
    }, [map, guessMarker, isGuessSubmitted]);

    const handleSubmitGuess = () => {
        if (!guessMarker || !map) return;

        const guessPosition = guessMarker.getPosition();
        submitGuess(guessPosition.toJSON());
        setIsGuessSubmitted(true);

        const actualLoc = new window.google.maps.LatLng(actualLocation.lat, actualLocation.lng);
        const newAnswerMarker = new window.google.maps.Marker({
            position: actualLoc,
            map: map,
            icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        });
        setAnswerMarker(newAnswerMarker);

        const newLine = new window.google.maps.Polyline({
            path: [guessPosition, actualLoc],
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map: map,
        });
        setLine(newLine);

        // Adjust map view to fit both markers
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(guessPosition);
        bounds.extend(actualLoc);
        map.fitBounds(bounds);
    };

    const handleNextQuestion = () => {
        onNext();
        setIsGuessSubmitted(false);

        if (guessMarker) guessMarker.setMap(null);
        if (answerMarker) answerMarker.setMap(null);
        if (line) line.setMap(null);

        setGuessMarker(null);
        setAnswerMarker(null);
        setLine(null);

        // Reset map view
        map.setCenter(usaCenter);
        map.setZoom(4);
    };

    return (
        <div className="mapContainer mt-4 mb-4"> {/* Bootstrap classes for margin top and bottom */}
            <div style={{border: '5px solid pink', borderRadius: '10px'}}>
            <div ref={mapRef} className="w-100" style={{height: '400px'}}></div>
            </div>
            {!isGuessSubmitted ? (
                <button className="btn btn-primary mt-3" style={{backgroundColor: "pink", border: 'none', borderRadius: '15px'}} onClick={handleSubmitGuess} disabled={!guessMarker}>Submit
                    Guess</button>
            ) : (
                <button className="btn btn-success mt-3 " style={{backgroundColor: "#a8d5ba", border: 'none', borderRadius: '15px'}} onClick={handleNextQuestion}>Next Question</button>
            )}
        </div>
    );
};

export default GuessMap;
