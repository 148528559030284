import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import {useNavigate} from "react-router-dom";
import { db } from './firebase'; // Ensure this path is correct
import GuessMap from './GuessMap';
import './ElliGuessr.css';
import logo from './images/ELLIGuessrLogo.png';
import * as faceapi from 'face-api.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const ElliGuessrPage = () => {
    const [currentImage, setCurrentImage] = useState(null);
    const [quizStarted, setQuizStarted] = useState(false);
    const [distanceMessage, setDistanceMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [isGuessSubmitted, setIsGuessSubmitted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadFaceApiModels().then(() => setLoading(false));
    }, []);

    const loadFaceApiModels = async () => {
        await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
        // Load other necessary models here
    };

    const fetchAndProcessRandomImage = async () => {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, 'photos'));
        const documents = querySnapshot.docs;

        if (documents.length > 0) {
            const randomIndex = Math.floor(Math.random() * documents.length);
            const randomDoc = documents[randomIndex];
            const data = randomDoc.data();
            processImage(data.url, data.location.split(',').map(Number));
        } else {
            console.log('No images found.');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (quizStarted) fetchAndProcessRandomImage();
    }, [quizStarted]);

    const processImage = async (imageUrl, location) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // Required for CORS compliance
        img.src = imageUrl;

        try {
            await img.decode(); // Ensure image is loaded before proceeding

            const detections = await faceapi.detectSingleFace(img, new faceapi.TinyFaceDetectorOptions());
            if (detections) {
                const padding = 50; // Adjust padding for more or less context around the face
                const contextSize = Math.max(detections.box.width, detections.box.height) + padding * 2;

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = contextSize;
                canvas.height = contextSize;

                ctx.drawImage(
                    img,
                    detections.box.x - padding,
                    detections.box.y - padding,
                    detections.box.width + padding * 2,
                    detections.box.height + padding * 2,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );

                const croppedImageUrl = canvas.toDataURL();
                setCurrentImage({url: croppedImageUrl, fullUrl: imageUrl, location: location});
            } else {
                setCurrentImage({url: imageUrl, fullUrl: imageUrl, location: location}); // Use the original if no face detected
            }
        } catch (error) {
            console.error("Error processing image:", error);
        } finally {
            setLoading(false);
        }
    };

    const submitGuess = (guess) => {
        if (!currentImage || !currentImage.location) return;
        const actualLocation = currentImage.location.map(Number); // Ensure it's in the correct format
        const distance = calculateDistance(guess, {lat: actualLocation[0], lng: actualLocation[1]});
        setIsGuessSubmitted(true);
        setDistanceMessage(`Your guess was ${distance.toFixed(2)} km away from the actual location.`);
    };


    function calculateDistance(point1, point2) {
        const R = 6371; // Radius of the Earth in km
        const dLat = (point2.lat - point1.lat) * Math.PI / 180;
        const dLon = (point2.lng - point1.lng) * Math.PI / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(point1.lat * Math.PI / 180) * Math.cos(point2.lat * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in km
    }


    const onNextQuestion = () => {
        setDistanceMessage("");
        setIsGuessSubmitted(false);
        setCurrentImage(null); // Reset the current image state
        fetchAndProcessRandomImage(); // Fetch and process a new random image
    };

    if (loading) {
        return <div className="centerContent">
            <div className="loadingText">Loading...</div>
        </div>;
    }

    return (
        <div className="container"> {/* Bootstrap container for centering and padding */}
            <div className="back-button-container mb-3 text-start">
                <button className="btn btn-link text-secondary" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="back-button-icon"/>
                </button>
            </div>
            {!quizStarted ? (
                <div className="centerContent">
                    <img src={logo} alt="Cute Bird" className="startImage mb-4" /> {/* Image above start button */}
                    <button className="btn-custom" onClick={() => setQuizStarted(true)}>Play</button>
                </div>
            ) : (
                <>
                    <h1 className="header mb-5">WHERE BIRB? 🦜🦜🦜</h1> {/* Header appears only when quiz starts */}
                    {loading && <div className="loadingText">Loading...</div>}
                    {currentImage && (
                        <img src={isGuessSubmitted ? currentImage.fullUrl : currentImage.url} alt="Quiz" className="imgCustom imageFrame" />
                    )}
                    {distanceMessage && <p className="text-center mt-3">{distanceMessage}</p>} {/* Bootstrap text-center class */}
                    <GuessMap submitGuess={submitGuess} actualLocation={currentImage ? { lat: currentImage.location[0], lng: currentImage.location[1] } : null} onNext={onNextQuestion} />

                </>
            )}
        </div>
    );
};

export default ElliGuessrPage;
